<template>
  <AuthLayout>
    <div v-if="validToken === 'validating'" class="flex justify-center">
      <LoadingIndicator />
    </div>
    <div v-if="validToken === false" class="text-center">
      {{ $tuf('verification_token_not_found') }}
    </div>
    <form v-if="validToken === true" class="grid grid-cols-1 gap-6" @submit.prevent="submit">
      <div class="col-span-1">
        <p>{{ $tuf('email_verified_sentence') }}</p>
      </div>
      <div class="col-span-1">
        <label for="password" class="text-sm text-prasset-gray-800 mb-2 block">{{ $tuf('new_password') }}</label>
        <PasswordField id="password" name="password" :placeholder="$t('password')" :required="true" v-model="password" />
      </div>
      <div class="col-span-1 flex flex-col items-center">
        <button
          class="button button--opague w-full max-w-xs relative"
          :class="{ 'opacity-25': loading }"
          type="submit"
          :disabled="loading">
          <span :class="{ 'invisible': loading }">
            {{ $tuf('confirm') }}
          </span>
          <span v-if="loading" class="absolute inset-0 flex justify-center items-center">
            <LoadingIndicator />
          </span>
        </button>
      </div>
    </form>
  </AuthLayout>
</template>

<script>
import { toRefs, onMounted, reactive } from '@vue/composition-api';
import AuthLayout from '@/modules/auth/views/layouts/auth';
import LoadingIndicator from '@/components/LoadingIndicator';
import PasswordField from '@/components/field/Password';
import useAuth from '@/compositions/useAuth';

export default {
  name: 'VerifyUser',

  components: {
    AuthLayout,
    LoadingIndicator,
    PasswordField,
  },

  setup(props, { root }) {
    const { logout, verify } = useAuth();

    logout();

    const state = reactive({
      token: root.$route.query.token,
      loading: false,
      validToken: 'validating',
      password: null,
    });

    async function validateToken() {
      if (state.token === undefined) {
        state.validToken = false;
        return;
      }

      try {
        await verify(state.token, null);
        state.validToken = true;
      } catch (error) {
        if (error.response.data.message === 'Invalid token.') {
          state.validToken = false;
        } else {
          state.validToken = true;
        }
      }
    }

    async function submit() {
      state.loading = true;

      try {
        await verify(state.token, state.password);

        root.$notify({
          type: 'success',
          title: root.$tuf('success'),
          text: root.$tuf('password_set_successfully'),
        });

        root.$router.push({ name: 'login' });
      } catch (err) {
        state.loading = false;

        root.$notify({
          type: 'error',
          title: root.$tuf('error'),
          text: root.$tuf('setting_password_failed'),
        });
      }
    }

    onMounted(() => {
      validateToken();
    });

    return {
      submit,
      ...toRefs(state),
    };
  },
};
</script>
